import { Component, Vue, Prop, Inject, Emit } from 'vue-property-decorator';
import CustomerSelector from '../../components/membership/CustomerSelector.vue';
import { BasicSelectListItem } from '@/common/types/AllTypesExports';

@Component({
    components: {
        CustomerSelector
    },
})
export default class LinkCompany extends Vue {
    @Prop({ required: false, default: () => 'Parent Member' })
    parentLabel!: string;

    @Prop({ required: false, default: () => 'Parent Member' })
    parentDescription!: string;

    @Prop({ required: false, default: () => '' })
    firstName!: string;

    @Prop({ required: false, default: false })
    allowAddNewParent!: boolean;

    parentCustomerId: number | null = null;
    parentCustomerName = '';

    @Inject('validator')
    $validator: any;

    onParentUpdated(newParent: BasicSelectListItem) {
        this.parentCustomerId = newParent.id;
        this.parentCustomerName = newParent.displayName;
        this.$emit("update:parent", newParent);
    }

    navigateToMembershipInfo() {
        window.location.href = '/membership-information';
    }

    navigateToContactUs() {
        window.location.href = '/contact-us';
    }
}