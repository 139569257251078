import { Component, Vue, Provide, Watch } from 'vue-property-decorator';
import {
    ApiErrorResponse,
    createDtoCustomer,
    CustomerFieldConfig,
    createCustomerFieldConfig,
    DtoMemberType,
    createDtoMemberType,
    CustomerType,
    DtoMemberField,
    DtoUserAccount,
    createDtoUserAccount,
    BasicSelectListItem,
    DtoAddress
} from '@/common/types/AllTypesExports';
import ProfileEdit from '../../components/membership/ProfileEdit.vue';
import { ICreateAccountGlobals } from './CreateAccountGlobals';
import { customerRepo } from '../../common/repositories/CustomerRepo';
import { AxiosResponse } from 'axios';
import NoviLogging from '../../common/services/NoviLogging';
import NoviStepper from '../../components/stepper/NoviStepper.vue'
import Step, { createStep } from '../../components/stepper/Step';
import ErrorAlert from '../../components/errors/ErrorAlert.vue';
import ValidationErrors from '../../components/errors/ValidationErrors.vue';
import AccountInfo from './AccountInfo.vue';
import { default as AccountInfoClass } from './AccountInfoVue';
import LinkCompany from './LinkCompany.vue';
import { DtoCustomer } from '../../common/types/membership/DtoCustomer';
import DuplicateChecker from '../../pages/create-account/DuplicateChecker.vue';
import { default as DuplicateCheckerClass } from '@/pages/create-account/DuplicateCheckerVue';
import ValidationService from '../../common/services/ValidationService';
import { default as ProfileEditClass } from '@/components/membership/ProfileEditVue';
import { DtoCustomerCreateResponse } from '../../common/types/membership/DtoCustomerCreateResponse';
import StringHelpers from '../../common/services/StringHelpers';
import { default as CustomerSelectorClass } from '@/components/membership/CustomerSelectorVue';
import RecaptchaInput from '@/components/inputs/recaptcha/RecaptchaInput.vue';
import RecaptchaInputClass from '../../components/inputs/recaptcha/RecaptchaInputVue';

declare let noviNs: ICreateAccountGlobals;

@Component({
    name: 'CreateAccount',
    components: {
        ProfileEdit,
        NoviStepper,
        ErrorAlert,
        ValidationErrors,
        AccountInfo,
        LinkCompany,
        DuplicateChecker,
        RecaptchaInput
    }
})
export default class CreateAccount extends Vue {
    userAccount: DtoUserAccount = createDtoUserAccount();
    customer: DtoCustomer = createDtoCustomer();
    parentCustomer: DtoCustomer = createDtoCustomer();
    memberVerificationCode: string = '';
    memberAccountVerified: boolean = false;
    customerFieldConfig: CustomerFieldConfig = createCustomerFieldConfig();
    companyFieldConfig: CustomerFieldConfig = createCustomerFieldConfig();
    memberType: DtoMemberType = createDtoMemberType();
    errorMessage: string = '';
    hasErrorOnSave: boolean = false;
    isSaving: boolean = false;
    steps: Array<Step> = [];
    currentStep : Step = createStep();
    emailIsAllowed: boolean = false;
    personIsAllowed: boolean = false;
    noParent: boolean = false;
    recaptchaToken: string = '';

    @Provide('validator') $validator: any = this.$validator;

    /// Computed Properties
    get customerFields() {
        const result: { [key: string]: DtoMemberField } = {};
        this.customerFieldConfig.memberFields.forEach(memberField => {
            if (!memberField) {
                return;
            }
            result[memberField.name.toLowerCase()] = memberField;
        });
        return result;
    }

    get companyFields() {
        const result: { [key: string]: DtoMemberField } = {};
        this.companyFieldConfig.memberFields.forEach(memberField => {
            if (!memberField) {
                return;
            }
            result[memberField.name.toLowerCase()] = memberField;
        });
        return result;
    }

    get parentMemberLabelForPeople(): string { return noviNs.parentMemberLabelForPeople };
    get parentMemberDescriptionForPeople(): string { return noviNs.parentMemberDescriptionForPeople };
    get allowNonMemberAccountSignup(): boolean { return noviNs.allowNonMemberAccountSignup };
    get parentMemberLabelForCompanies(): string { return noviNs.parentMemberLabelForCompanies };
    get parentMemberDescriptionForCompanies(): string { return noviNs.parentMemberDescriptionForCompanies };

    get billingAddressInstructionsForPeople(): string { return noviNs.billingAddressInstructionsForPeople };
    get shippingAddressInstructionsForPeople(): string { return noviNs.shippingAddressInstructionsForPeople };
    get billingAddressInstructionsForCompanies(): string { return noviNs.billingAddressInstructionsForCompanies };
    get shippingAddressInstructionsForCompanies(): string { return noviNs.shippingAddressInstructionsForCompanies };
    get personalAddressInstructions(): string { return noviNs.personalAddressInstructions };
    get professionalEmailInstructions(): string { return noviNs.professionalEmailInstructions };
    get personalEmailInstructions(): string { return noviNs.personalEmailInstructions };
    get emailInstructionsForCompanies(): string { return noviNs.emailInstructionsForCompanies };

    get showNewCustomer() {
        return this.customer.parentCustomerId == 0;
    }

    get showLinkCompanyStep() {
        return !noviNs.hasPersonMemberTypes && !this.memberAccountVerified;
    }

    get hasPersonMemberTypes() {
        return noviNs.hasPersonMemberTypes;
    }

    get hasCompanyMemberTypes() {
        return noviNs.hasCompanyMemberTypes;
    }

    get hasBothCustomerTypes() {
        return (noviNs.hasPersonMemberTypes && noviNs.hasCompanyMemberTypes);
    }

    get nextStepText() {
        return this.currentStep == this.keyInfoStep ? 'Create Account' : 'Save and Continue';
    }

    get showConnectedMessage() {
        return (this.customer.parentCustomerId || this.customer.parentCustomerId == 0);
    }

    get disableNext() {
        return (this.errors && this.errors.count() > 0)
            || this.isSaving
            || (this.currentStep == this.loginInfoStep && !this.emailIsAllowed)
            || (this.currentStep == this.keyInfoStep && (!this.personIsAllowed || (this.parentMemberFieldRequired && !this.noParent && this.customer.parentCustomerId == null)));
    }

    get personProvided() {
        return this.customer && this.customer.firstName && this.customer.lastName && this.customer.email && ((this.customer.parentCustomerId != null) || this.noParent);
    }

    get showParentMemberFieldInKeyInfoStep() {
        var parentMemberField = this.customerFieldConfig.memberFields.find(mf => mf.name.toLowerCase() == "parentcustomername");
        if (parentMemberField) {
            return parentMemberField.keyInformation;
        }
        return false;
    }

    get parentMemberFieldRequired() {
        var parentMemberField = this.customerFieldConfig.memberFields.find(mf => mf.name.toLowerCase() == "parentcustomername");
        if (parentMemberField) {
            return parentMemberField.requiredOnFrontend;
        }
        return false;
    }

    private loginInfoStep: Step = {
        name: "login-info",
        title: "Login Info",
        iconClass: "fas fa-user",
        completed: false
    };
    private linkCompanyStep: Step = {
        name: "link-company",
        title: `Link ${this.parentMemberLabelForPeople}`,
        iconClass: "fas fa-building",
        completed: false
    };
    private keyInfoStep: Step = {
        name: "key-info",
        title: "Key Info",
        iconClass: "fas fa-key",
        completed: false
    };

    ///Life cycle events

    created() {
        this.customer.customerType = CustomerType.Person;
        this.getFieldConfig();

        //this.currentStep = this.keyInfoStep;
        this.currentStep = this.loginInfoStep;

        this.steps.push(this.loginInfoStep);
        if (this.showLinkCompanyStep) {
            this.steps.push(this.linkCompanyStep);
        }
        this.steps.push(this.keyInfoStep);        
    }

    mounted() {
        if (noviNs.emailInput.length > 0) {
            this.userAccount.email = noviNs.emailInput;
            this.customer.email = this.userAccount.email;
            this.checkForDuplicateEmail();
        }
    }

    //Public Functions
    onUserAccountUpdated() {
        this.customer.firstName = this.userAccount.firstName;
        this.customer.lastName = this.userAccount.lastName;
        this.customer.name = `${this.userAccount.firstName} ${this.userAccount.lastName}`;
        this.customer.email = this.userAccount.email;
    }

    onCustomerUpdated(customer: DtoCustomer) {
        Object.assign(this.customer, customer);
        this.userAccount.firstName = this.customer.firstName || "";
        this.userAccount.lastName = this.customer.lastName || "";
    }

    onNameUpdated() {
        if (!this.memberAccountVerified) {
            this.checkForDuplicates();
        }
    }

    onEmailUpdated() {
        this.checkForDuplicates();
    }

    onParentCompanyLinked(newParent: BasicSelectListItem) {
        if (!this.memberAccountVerified) {
            this.customer.parentCustomerId = newParent.id;
            this.customer.parentCustomerName = newParent.displayName;
            this.getFieldConfig();
        }
    }

    onParentUpdated(newParent: BasicSelectListItem) {
        if (!this.memberAccountVerified) {
            this.checkForDuplicatePerson();
        }

        if (this.customer.parentCustomerId == 0) {
            if (this.parentCustomer.name != newParent.displayName) {
                this.parentCustomer.name = newParent.displayName;
                this.checkForDuplicatePerson();
            }
        } else {
            this.parentCustomer = createDtoCustomer();
        } 

        this.getFieldConfig();
    }

    onParentNameUpdated() {
        this.$root.$emit('update-new-item-name', this.parentCustomer.name);
    }

    onNoParentToggled(noParent: boolean) {
        this.noParent = noParent; // When this is toggled ON (not OFF), onParentUpdated() is also called, so don't make call to check for dupes here
        this.validateProfiles();
    }

    onMemberAccountVerified(verified: boolean) {
        this.memberAccountVerified = verified;

        // Remove the "Link Parent Company" step when member account verified
        if (!this.showLinkCompanyStep && this.steps[1] == this.linkCompanyStep) {
            if (this.steps[1] == this.linkCompanyStep) {
                this.steps.splice(1, 1);
            }
        }
        // Restore the "Link Parent Company" step when verification is reset
        else if (this.showLinkCompanyStep && this.steps[1] != this.linkCompanyStep) {
            this.steps.splice(1, 0, this.linkCompanyStep);
        }
    }

    onParentCustomerBillingAddressUpdated() {
        Object.assign(<DtoAddress>this.customer.parentBilling, this.parentCustomer.billingAddress);
        if (this.customer.useParentBilling) {
            Object.assign(<DtoAddress>this.customer.billingAddress, this.parentCustomer.billingAddress);
        }
    }

    onParentCustomerShippingAddressUpdated() {
        Object.assign(<DtoAddress>this.customer.parentShipping, this.parentCustomer.shippingAddress);
        if (this.customer.useParentShipping) {
            Object.assign(<DtoAddress>this.customer.shippingAddress, this.parentCustomer.shippingAddress);
        }
    }

    checkForDuplicates() {
        if (this.currentStep == this.loginInfoStep)
            this.checkForDuplicateEmail();
        else
            this.checkForDuplicatePerson();
    }

    checkForDuplicateEmail() {
        let duplicateChecker = <DuplicateCheckerClass>this.$refs.duplicateEmailChecker;
        duplicateChecker.validateEmailAddress()
            //.then((result: void | DuplicateCheckResult | AxiosResponse<ApiErrorResponse>) => { })
            .catch((error: AxiosResponse<ApiErrorResponse>) => { });
    }

    checkForDuplicatePerson() {
        if (!this.personProvided)
            return;

        let duplicateChecker = <DuplicateCheckerClass>this.$refs.duplicatePersonChecker;
        duplicateChecker.validatePerson()
            //.then((result: void | DuplicateCheckResult | AxiosResponse<ApiErrorResponse>) => { })
            .catch((error: AxiosResponse<ApiErrorResponse>) => { });
    }

    validateProfiles() {
        let profile = <ProfileEditClass>this.$refs.profile;
        profile.validate();
    }

    saveAndNext() {
        if (this.currentStep == this.keyInfoStep) {
            this.validateProfiles();
        } 

        if (this.currentStep == this.loginInfoStep) {
            let accountInfo = <AccountInfoClass>this.$refs.accountInfoRef;
            accountInfo.validatePasswordSecurity();
        }

        this.$validator.validate()
            .then((valid: any) => {
                if (valid && !this.$validator.errors.any()) {
                    if (this.currentStep == this.keyInfoStep) {
                        this.saveUserAccount();
                    }
                    else {
                        this.moveToNextStep();
                    }
                }
                else {
                    NoviLogging.info("Validation Failed");
                    ValidationService.scrollToFirstInvalidField(this.$el);
                }
            });            
    }

    onKeyInfoMounted() {
        var firstInput = this.$el.querySelector("input");
        if (firstInput) {
            (<HTMLElement>firstInput).focus();
        }
        // Once key info is mounted, we need to run the duplicate checks, assuming the user hasn't already verified their access to an existing account
        if (!this.memberAccountVerified || !this.showParentMemberFieldInKeyInfoStep) {
            this.checkForDuplicatePerson();
        }
        else {
            // If they've already been verified, then let's skip the duplicate check. The checks will still run if any user info changes
            this.personIsAllowed = true;
        }
    }

    //Private Functions
    private saveUserAccount() {
        this.isSaving = true;
        customerRepo.createUserAccount(this.customer, this.userAccount, this.memberVerificationCode, this.parentCustomer, this.recaptchaToken)
            .then((result: DtoCustomerCreateResponse) => {
                if (result.isAutoLoggedIn) {
                    if (noviNs.returnUrl) {
                        window.location.href = noviNs.returnUrl;
                    } else {
                        window.location.href = `/member-compass`;
                    }
                } else {
                    var returnUrlQueryString = "";

                    if (noviNs.returnUrl) {
                        returnUrlQueryString = "&returnUrl=" + noviNs.returnUrl;
                    }

                    window.location.href = `/confirm-email?userId=` + result.userId + returnUrlQueryString;
                }
        })
        .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
            this.handleSaveUserAccountError(axiosResponse);            
        });        
    }

    private handleSaveUserAccountError(axiosResponse: AxiosResponse<ApiErrorResponse>) {
        this.isSaving = false;
        this.errors.clear(CustomerType.Person);

        var validationErrors = axiosResponse.data.links;
        var hasValidationErrors = Object.entries(validationErrors).length > 0;

        let recaptchaInput = <RecaptchaInputClass>this.$refs.recaptcha;
        recaptchaInput.reset();

        if (!hasValidationErrors) {
            this.errorMessage = `Could not create your account: ${axiosResponse.data.message}`;
            this.hasErrorOnSave = true;
            return;
        }
        
        this.errorMessage = "";
        this.hasErrorOnSave = false;

        // Individual error keys look like "request.customer.phone"
        var individualValidationErrors = Object.keys(validationErrors)
            .filter(error => !error.includes("parent"))
            .reduce((obj: any, key) => {
                obj[key] = validationErrors[key];
                return obj;
            }, {});

        ValidationService.parseValidationErrors(individualValidationErrors, CustomerType.Person, this.customerFields, this.$validator);

        if (this.customer.parentCustomerId == 0) {
            // Company error keys look like "request.parentcustomer.phone"
            var companyValidationErrors = Object.keys(validationErrors)
                .filter(error => error.includes("parent"))
                .reduce((obj: any, key) => {
                    obj[key] = validationErrors[key];
                    return obj;
                }, {});

            ValidationService.parseValidationErrors(companyValidationErrors, CustomerType.Company, this.companyFields, this.$validator);
        } 

        ValidationService.scrollToFirstInvalidField(this.$el);        
    }

    private moveToNextStep() {
        this.currentStep.completed = true;
        var nextStep = this.steps[this.steps.indexOf(this.currentStep) + 1];
        this.currentStep = nextStep;
        window.scrollTo({ top: (<HTMLElement>this.$refs.container).offsetTop });
    }

    private getFieldConfig() {
        var personFieldConfigPromise = customerRepo.getFieldConfiguration(false, true, this.memberType.id, this.customer.parentCustomerId)
            .then((fieldConfig) => {
                this.customerFieldConfig = Object.assign(this.customerFieldConfig, fieldConfig);
                return this.customerFieldConfig;
            })
            .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                this.errorMessage = `Unable to load the field configuration information. ${axiosResponse.data.message}`;
                throw axiosResponse;
            });
        var companyFieldConfigPromise = customerRepo.getFieldConfiguration(true, true, this.memberType.id)
            .then((fieldConfig) => {
                this.companyFieldConfig = Object.assign(this.companyFieldConfig, fieldConfig);
                return this.companyFieldConfig;
            })
            .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                this.errorMessage = `Unable to load the field configuration information. ${axiosResponse.data.message}`;
                throw axiosResponse;
            });
    }
}